import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import { DuplicateAdGroupRequest } from '../types/DuplicateAdGroupRequest'

export class CampaignSummaryService extends ApiService {
  duplicateAndUpdateAdGroup(adGroupToBeDuplicated: DuplicateAdGroupRequest): AxiosPromise<number> {
    return this.request({
      url: 'api/AdGroup/copyEdit',
      method: 'POST',
      data: adGroupToBeDuplicated,
    })
  }

  duplicateAdGroup(adGroupId: number): AxiosPromise<void> {
    return this.request({
      url: `api/AdGroup/copy/${adGroupId}`,
      method: 'GET',
    })
  }

  activateCampaign(campaignId: number | string): AxiosPromise<void> {
    return this.request({
      url: `api/Campaign/restart/${campaignId}`,
      method: 'GET',
    })
  }

  deactivateCampaign(campaignId: number | string): AxiosPromise<void> {
    return this.request({
      url: `api/Campaign/pause/${campaignId}`,
      method: 'GET',
    })
  }
}
