import { ChannelENUM } from './ChannelENUM'

export enum AccountType {
  Admin = 0,
  Advertiser = 1,
  Marketer = 2,
  AdvertiserGroup = 3,
}

export type AccountExchangeSettingDTO = {
  channelId: ChannelENUM
  externalId?: string | null
  extra?: string | null
}

export type AccountDTO = {
  id: number
  name: string
  marketerId: number
  exchangeSettings?: Record<number, AccountExchangeSettingDTO>
  twitterAccountId?: string
  linkedInAccountId?: string
  externalDSPSeatId?: string
  externalDSPId?: number
  tikTokId?: string
  snapchatId?: string
  xandrDMPSeatId?: string
  accountType?: AccountType
}

export type AccountOptionTypeDTO = {
  id: number
  name: string
  description: string
  key: string
  groupName: null
  appliesToAccountType: number
  type: string
  validation: null
  source: null
  invert: null
  sortOrder: number
  defaultValue: string | null
  required: boolean
}

export enum AccountOptionId {
  EndemicBlend = 10150,
  PublisherEnabled = 10120,
}

export type AccountOptionDTO = {
  optionId: number
  enabled: boolean
  value: string
  accountId: AccountOptionId | number
}

export type GetAccountsResponse = {
  accountOptionTypes: AccountOptionTypeDTO[]
  accountOptions: AccountOptionDTO[]
  accounts: AccountDTO[]
  managers: unknown[]
  marketerContracts: unknown[]
}
