import { BudgetTypeENUM } from '../types'
import { BudgetType } from '../../generated'

// Map budgetType from new API to legacy API
export const budgetTypeActApiToLegacy: Record<BudgetType, BudgetTypeENUM> = {
  [BudgetType.IMPRESSIONS]: BudgetTypeENUM.Impressions,
  [BudgetType.SPEND]: BudgetTypeENUM.Spend,
}

// Map budgetType from legacy API to new API
export const budgetTypeLegacyToActApi: Record<BudgetTypeENUM, BudgetType> = {
  [BudgetTypeENUM.Impressions]: BudgetType.IMPRESSIONS,
  [BudgetTypeENUM.Spend]: BudgetType.SPEND,
}
