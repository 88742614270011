export type GetExecutionFileRequest = {
  workflowConfigId: string
  workflowConfigDataset: string
  workflowExecutionFileId: string
}

export type EarlyUpdateRequest = {
  workflowConfigId: string
  workflowExecutionFileId: string
  workflowConfigDataset: string
}

export type ReprocessRequest = {
  workflowExecutionFileId: string
  workflowConfigDataset: string
}

export enum ExecutionStageName {
  SetEntitySegments = 'SetEntitySegments',
  CombineExport = 'CombineExport',
  UpdateAdgroups = 'UpdateAdgroups',
  MessageTransactions = 'MessageTransactions',
  EmailNotify = 'EmailNotify',
  ValidateNpis = 'ValidateNpis',
  ValidateCreatives = 'ValidateCreatives',
  PartialValidateWorkflow = 'PartialValidateWorkflow',
  ValidateWorkflow = 'ValidateWorkflow',
  ObjectMetadata = 'ObjectMetadata',
  AudienceStatistics = 'AudienceStatistics',
}

export enum ExecutionStageNameUi {
  NewAudienceFileReceived = 'New Audience File Received',
  AudienceUploaded = 'Audience Uploaded',
  AdGroupsUpdated = 'Ad Group(s) Updated',
  WorkflowValidationsComplete = 'Workflow Validations Complete',
}

export type ExecutionStage = {
  name: ExecutionStageName
  completed: boolean
  reason: string | null
  active: boolean
  exceptionMessage: string | null
  startDateTime: string | null
  endDateTime: string | null
}

export type Keycode = {
  keycode: string
  contractConfirmationId?: string // this field is present only in execution file of PROACT workflow config
}

export type WorkflowExecutionFileAudience = {
  audienceId: number
  audienceName: string
  npiCounts: number
}

export type WorkflowExecutionFile = {
  id: string
  name: string
  createdDate: string
  filePath: string
  canEarlyUpdate: boolean
  canReprocess: boolean
  inputMessage?: string
  error?: string
  rowsCount: number
  adgroupIds: string[]
  campaignIds: string[]
  audienceIds: string[]
  keycodes: Keycode[]
  status: 'Downloaded' | 'Processed' | 'Error' | 'Skipped'
  audienceChannel: 'Social' | 'Programmatic' | 'Both'
  stages: ExecutionStage[]
  timeWarning: boolean
  workflowReport?: string
  runState?: string
  audiences: WorkflowExecutionFileAudience[]
  link: string
}

export type WorkflowExecutionFileFilters = {
  searchQuery: string
  status: string
  audienceChannel: string
}
