import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import { GetLocationGroupsRequest, GetLocationGroupsResponse } from '../types/locations'

export class LocationsService extends ApiService {
  getLocationGroups({ marketerId, accountId }: GetLocationGroupsRequest): AxiosPromise<GetLocationGroupsResponse> {
    return this.request({
      url: 'api/audience',
      method: 'GET',
      params: {
        marketerId,
        accountId,
        audienceType: 'Location',
      },
    })
  }
}
