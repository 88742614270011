import { OptimizationGoalTypeENUM } from '../types'
import { OptimizationGoalType } from '../../generated'

// Map optimizationGoalType from new API to legacy API
export const optimizationGoalTypeActApiToLegacy: Record<OptimizationGoalType, OptimizationGoalTypeENUM> = {
  [OptimizationGoalType.CTR]: OptimizationGoalTypeENUM.ctr,
  [OptimizationGoalType.CPC]: OptimizationGoalTypeENUM.cpc,
  [OptimizationGoalType.CPA]: OptimizationGoalTypeENUM.cpa,
}

// Map optimizationGoalType from legacy API to new API
export const optimizationGoalTypeLegacyToActApi: Record<OptimizationGoalTypeENUM, OptimizationGoalType> = {
  [OptimizationGoalTypeENUM.ctr]: OptimizationGoalType.CTR,
  [OptimizationGoalTypeENUM.cpc]: OptimizationGoalType.CPC,
  [OptimizationGoalTypeENUM.cpa]: OptimizationGoalType.CPA,
}
