import { BudgetCapTypeENUM } from '../types'
import { BudgetCapType } from '../../generated'

// Map budgetCapType from new API to legacy API
export const budgetCapTypeActApiToLegacy: Record<BudgetCapType, BudgetCapTypeENUM> = {
  [BudgetCapType.DAILY]: BudgetCapTypeENUM.daily,
  [BudgetCapType.LIFETIME]: BudgetCapTypeENUM.lifetime,
  [BudgetCapType.WEEKLY]: BudgetCapTypeENUM.weekly,
}

// Map budgetCapType from legacy API to new API
export const budgetCapTypeLegacyToActApi: Record<BudgetCapTypeENUM, BudgetCapType> = {
  [BudgetCapTypeENUM.daily]: BudgetCapType.DAILY,
  [BudgetCapTypeENUM.lifetime]: BudgetCapType.LIFETIME,
  [BudgetCapTypeENUM.weekly]: BudgetCapType.WEEKLY,
}
