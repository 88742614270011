import { objEntries, objFromEntries } from '@lasso/shared/utils'

import { ChannelType } from '../../generated'
import { ChannelENUM } from '../types'

// Map channelId from new API to legacy API
export const channelActApiToLegacy: Record<ChannelType, ChannelENUM> = {
  [ChannelType.NONE]: ChannelENUM.None,
  [ChannelType.BANNER]: ChannelENUM.Banner,
  [ChannelType.VIDEO]: ChannelENUM.Video,
  [ChannelType.FACEBOOK_INSTAGRAM]: ChannelENUM.FacebookInstagram,
  [ChannelType.CONNECTED_TV]: ChannelENUM.ConnectedTV,
  [ChannelType.AUDIO]: ChannelENUM.Audio,
  [ChannelType.EMAIL]: ChannelENUM.Email,
  [ChannelType.SEARCH]: ChannelENUM.Search,
  [ChannelType.TWITTER]: ChannelENUM.Twitter,
  [ChannelType.DATA_ONLY]: ChannelENUM.DataOnly,
  [ChannelType.MEASUREMENT]: ChannelENUM.Measurement,
  [ChannelType.LINKED_IN]: ChannelENUM.LinkedIn,
  [ChannelType.EXTERNAL_DSP]: ChannelENUM.ExternalDSP,
  [ChannelType.TIK_TOK]: ChannelENUM.TikTok,
  [ChannelType.SNAPCHAT]: ChannelENUM.Snapchat,
  [ChannelType.XANDR_DMP]: ChannelENUM.XandrDMP,
  [ChannelType.ENDEMIC_HCP]: ChannelENUM.Endemic,
  [ChannelType.ENDEMIC_DTC]: ChannelENUM.EndemicDTC,
  [ChannelType.ENDEMIC_EHR]: ChannelENUM.EndemicEHR,
  [ChannelType.TRADE_DESK_DMP]: ChannelENUM.TradeDeskDMP,
  [ChannelType.LIVERAMP]: ChannelENUM.LiveRamp,
  [ChannelType.DIRECT_SOLD]: ChannelENUM.DirectSold,
  [ChannelType.REDDIT]: ChannelENUM.Reddit,
  [ChannelType.YAHOO]: ChannelENUM.Yahoo,
  [ChannelType.ADTHEORENT]: ChannelENUM.Adtheorent,
}

export const channelLegacyToActApi: Record<ChannelENUM, ChannelType> = objFromEntries(
  objEntries(channelActApiToLegacy).map(([a, b]) => [b, a]),
)
