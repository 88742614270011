import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import { cfUrl, workflowUiCfs } from '../consts/workflowUiCfs'
import {
  EarlyUpdateRequest,
  GetExecutionFileRequest,
  ReprocessRequest,
  WorkflowExecutionFile,
} from '../types/workflowExecutionFile'

export class WorkflowExecutionFileService extends ApiService {
  getExecutionFile({ workflowConfigId, workflowExecutionFileId, workflowConfigDataset }: GetExecutionFileRequest): AxiosPromise<WorkflowExecutionFile> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.executionFiles.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.executionFiles.handlers.details,
          workflowConfigId,
          workflowConfigDataset,
          executionFileId: workflowExecutionFileId,
        }),
      },
    })
  }

  earlyUpdate({ workflowConfigId, workflowExecutionFileId, workflowConfigDataset }: EarlyUpdateRequest): AxiosPromise<void> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.executionFiles.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.executionFiles.handlers.earlyUpdate,
          workflowConfigId,
          workflowConfigDataset,
          executionFileId: workflowExecutionFileId,
        }),
      },
    })
  }

  reprocess({ workflowConfigDataset, workflowExecutionFileId }: ReprocessRequest): AxiosPromise<void> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.executionFiles.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.executionFiles.handlers.reprocess,
          workflowConfigDataset,
          executionFileId: workflowExecutionFileId,
        }),
      },
    })
  }
}
