import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import { cfUrl, workflowUiCfs } from '../consts/workflowUiCfs'
import { GetSftpCredentialsRequest, SaveSftpCredentialsRequest } from '../types/sftpCredentials'

export class SftpCredentialsService extends ApiService {
  saveSftpCredentials({ sftpCredentials, workflowConfigId, sftpCredentialsSecret }: SaveSftpCredentialsRequest): AxiosPromise<void> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.sftp.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.sftp.handlers.saveSftpCredentials,
          sftp: sftpCredentials,
          sftpCredentialsSecret,
          workflowConfigId,
        }),
      },
    })
  }

  getSftpCredentials(sftpCredentialsSecret: string): AxiosPromise<GetSftpCredentialsRequest> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.sftp.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.sftp.handlers.getSftpCredentials,
          sftpCredentialsSecret,
        }),
      },
    })
  }
}
