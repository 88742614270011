import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import {
  GetExecutionFilesRequest, GetExecutionFilesResponse, GetWorkflowConfigResponse,
  GetWorkflowConfigsRequest,
  GetWorkflowConfigsResponse, RunState,
  WorkflowConfig,
} from '../types/workflowConfig'
import { cfUrl, workflowUiCfs } from '../consts/workflowUiCfs'

export class WorkflowConfigService extends ApiService {
  getWorkflowConfigs(payload: GetWorkflowConfigsRequest): AxiosPromise<GetWorkflowConfigsResponse> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.configs.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.configs.handlers.list,
          ...payload,
        }),
      },
    })
  }

  getWorkflowConfig(workflowConfigId: string): AxiosPromise<GetWorkflowConfigResponse> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.configs.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.configs.handlers.details,
          id: workflowConfigId,
        }),
      },
    })
  }

  activate(workflowConfigId: string): AxiosPromise<void> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.configs.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.configs.handlers.changeRunState,
          workflowConfigId,
          runState: RunState.Active,
        }),
      },
    })
  }

  deactivate(workflowConfigId: string): AxiosPromise<void> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.configs.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.configs.handlers.changeRunState,
          workflowConfigId,
          runState: RunState.Paused,
        }),
      },
    })
  }

  getExecutionFiles(payload: GetExecutionFilesRequest): AxiosPromise<GetExecutionFilesResponse> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.executionFiles.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.executionFiles.handlers.list,
          ...payload,
        }),
      },
    })
  }

  createWorkflowConfig(workflowConfig: WorkflowConfig): AxiosPromise<WorkflowConfig> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.configs.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.configs.handlers.createWorkflowConfig,
          workflowConfig: {
            ...workflowConfig,
            emailTo: workflowConfig.emailTo.filter(email => !!email),
          },
        }),
      },
    })
  }

  updateWorkflowConfig(workflowConfig: WorkflowConfig): AxiosPromise<WorkflowConfig> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.configs.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.configs.handlers.updateWorkflowConfig,
          id: workflowConfig.id,
          workflowConfig: {
            ...workflowConfig,
            emailTo: workflowConfig.emailTo.filter(email => !!email),
          },
        }),
      },
    })
  }
}
