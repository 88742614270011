import { createInjectionState } from '@lasso/shared/utils'
import { AxiosInstance } from 'axios'

import { bindApiMethods } from '@lasso/api-shared'

import { useApiCache } from '@lasso/shared/hooks'

import { ActivationLegacyApi } from './ActivationLegacyApi'

export const [useProvideActivationLegacyApi, useActivationLegacyApi] = createInjectionState(
  (axiosInstance: AxiosInstance) => {
    const [api, methodsMapping] = bindApiMethods(new ActivationLegacyApi(axiosInstance))

    useApiCache().aliasCacheKeys(methodsMapping)

    return api
  },
  { throwWhenNotProvided: 'Activation Legacy API was not provided' },
)
