import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import { GetAccountsResponse } from '../types/account'

export class AccountService extends ApiService {
  getAccounts(): AxiosPromise<GetAccountsResponse> {
    return this.request({
      url: 'api/companyAccount/all',
      method: 'GET',
    })
  }
}
