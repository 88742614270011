const CF_INTEGRATION_NPI_BACKEND_HTTP = 'cf-integration-npi-backend-http'

export const cfUrl = 'api/Account/CloudFunctionCall/'

export const workflowUiCfs = {
  configs: {
    cfName: CF_INTEGRATION_NPI_BACKEND_HTTP,
    handlers: {
      list: 'workflow_configs_list',
      details: 'workflow_configs_details',
      changeRunState: 'workflow_configs_change_run_state',
      createWorkflowConfig: 'workflow_configs_create_workflow_config',
      updateWorkflowConfig: 'workflow_configs_update_workflow_config',
    },
  },
  executionFiles: {
    cfName: CF_INTEGRATION_NPI_BACKEND_HTTP,
    handlers: {
      list: 'execution_files_list',
      details: 'execution_files_details',
      earlyUpdate: 'execution_files_early_update',
      reprocess: 'execution_files_reprocess',
    },
  },
  sftp: {
    cfName: CF_INTEGRATION_NPI_BACKEND_HTTP,
    handlers: {
      saveSftpCredentials: 'sftp_save_credentials',
      getSftpCredentials: 'sftp_get_credentials',
    },
  },
  creativeFiles: {
    cfName: CF_INTEGRATION_NPI_BACKEND_HTTP,
    handlers: {
      list: 'get_creative_mapping_files_list',
      doesCreativeFileForAdGroupExist: 'creative_mapping_files_does_file_for_ad_group_exist',
    },
  },
}
