export enum DateRangeENUM {
  Today = 1,
  Yesterday = 2,
  ThisWeek = 3,
  LastWeek = 4,
  Last7Days = 7,
  Last14Days = 14,
  Last30Days = 30,
  Last90Days = 90,
  LastMonth = 31,
  ThisMonth = 32,
  ThisQuarter = 33,
  LastQuarter = 34,
  ExactDay = 50,
  ExactRange = 60,
  ThisYear = 70,
  LastYear = 80,
  AllTime = 100,
}
